@font-face {
  font-family: fira-code;
  src: url("FiraMono-Medium.ba889975.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  color: #d4e0e1;
  background-color: #29272f;
  margin: 0;
  padding: 2rem;
  font-family: fira-code, monospace;
}

html {
  font-size: 1.2rem;
}

@media (width <= 850px) {
  html {
    font-size: .8rem;
  }
}

button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

a.text {
  border-bottom: 1px solid;
  text-decoration: none;
}
/*# sourceMappingURL=index.1d659fb2.css.map */
