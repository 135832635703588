@font-face
  font-family: "fira-code"
  src: url('/assets/FiraMono-Medium.ttf') format('truetype')

*
  box-sizing: border-box

body
  font-family: "fira-code", monospace
  background-color: #29272f
  color: #d4e0e1
  margin: 0
  padding: 2rem

html
  font-size: 1.2rem
  @media (max-width: 850px)
    font-size: .8rem

button
  background: none
  color: inherit
  font-family: inherit
  outline: none
  margin: 0
  padding: 0
  border: none
  font-size: inherit

a
  color: inherit

  &.text
    text-decoration: none
    border-bottom: 1px solid
